@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-carousel {
  position: relative;

  &__container {
    @extend %scrollbar-hidden;

    display: flex;
    gap: spacing(2);
    height: spacing(48);
    padding: spacing(2);
    overflow: auto clip;
    scrollbar-gutter: stable;

    .nax-media img {
      @include rounded--large;

      max-width: unset;
      height: 100%;
      object-fit: cover;
      border: spacing(px) solid $sys-color-outline;
    }
  }

  &__nav-button {
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);

    &:first-child {
      left: 0;
    }

    &:last-child {
      right: 0;
    }
  }

  &__image-wrapper {
    position: relative;
  }

  &__delete-button {
    position: absolute;
    top: spacing(3);
    right: spacing(3);
  }
}
