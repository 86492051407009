@use "../01-abstracts" as *;

.nax-main {
  display: flex;
  flex-direction: column;
  height: 100dvh;

  & > .nax-main__header {
    display: none;
    grid-area: header;
    height: spacing(13);
    padding-inline: spacing(5);
    background-color: $sys-color-surface_container_lowest;
    border-bottom: spacing(px) solid var(--sys-color-outline_variant);

    @include for-orientation("landscape") {
      display: unset;
      height: spacing(14);
    }

    & > nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
    }
  }

  &:has(.nax-home) {
    .nax-main__header {
      display: unset;
    }

    .nax-breadcrumb {
      display: none;
    }
  }
}
