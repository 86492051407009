@use "../../01-abstracts" as *;
@use "../../02-tools" as *;
@use "../../06-objects" as *;
@use "./prime-global" as *;

.p-multiselect {
  @include rounded--small;
  @extend %prime-input-common;

  width: 100%;
  min-width: spacing(50);
  max-width: spacing(72);
  outline-color: $sys-color-outline;

  .p-multiselect-label {
    @include body--medium;

    padding-block: spacing("1_5");
    padding-inline: spacing(3) spacing(1);
    color: $sys-color-on-surface;

    &.p-placeholder {
      color: $sys-color-outline;
    }
  }

  .p-multiselect-trigger {
    width: unset;
    padding-inline-end: spacing(1);

    &-icon {
      color: $sys-color-on-surface;
    }
  }

  &:not(.p-disabled).p-focus {
    outline-width: spacing("0_5");
    outline-offset: -(spacing("0_5"));
    box-shadow: none;
  }

  @include user-action-states(
    var(--sys-color-on_secondary_container),
    var(--sys-color-surface_container)
  );
}

.p-multiselect-panel {
  padding-block: spacing(2);
  overflow: hidden;
  background-color: $sys-color-surface_container_highest;

  .p-multiselect-header {
    padding: spacing("1_5") spacing(3);
    margin-bottom: spacing("1_5");
    color: $sys-color-on_surface;
    background-color: $sys-color-surface_container_highest;
  }

  p-multiselectitem {
    @extend %o-hr-after;

    .p-multiselect-item {
      @include label--large;

      gap: spacing(3);
      padding: spacing("1_5") spacing(3);
      margin-bottom: spacing("1_5");
      color: $sys-color-on_surface;

      @include user-action-states(
        $sys-color-on_surface,
        var(--sys-color-surface_container_highest)
      );

      &.p-highlight {
        @include state-color-mix(
          $sys-color-on_surface,
          $sys-color-surface_container_highest,
          $state-active
        );
      }
    }

    .p-checkbox {
      margin: 0;
    }
  }
}
