@use "../../01-abstracts" as *;
@use "../../02-tools" as *;

p-calendar {
  .p-inputtext {
    @include body--medium;
    @include rounded--extra-small;
    @include user-action-states($sys-color-on_surface, $sys-color-surface);

    border: spacing(px) solid $sys-color-outline_variant;

    // Adjust padding if no icon is present
    &:not(& > *) {
      padding-inline: spacing(3);
    }
  }

  nax-product-date-input & {
    /* stylelint-disable-next-line no-descending-specificity */
    .p-inputtext {
      max-width: spacing(40);
      text-align: center;
    }
  }
}
