@use "../01-abstracts" as *;

.nax-accordion {
  width: min(spacing(128), 100%);

  &__expand-action-container {
    width: spacing(14);
  }

  &__button {
    @include user-action-states(
      $sys-color-on_surface,
      $sys-color-surface_container
    );

    &--expanded {
      transform: rotate(180deg);
    }
  }
}
