@use "../../01-abstracts" as *;
@use "../../02-tools" as *;
@use "./prime-global" as *;

.input-text {
  .p-inputtext {
    @include body--medium;
    @extend %prime-input-common;

    width: 100%;
    height: spacing(10);

    &::placeholder {
      color: $sys-color-outline;
    }

    @include user-action-states($sys-color-on_surface, $sys-color-surface);

    &.size--small {
      height: spacing(8);
    }
  }

  .nax-search & .p-inputtext {
    width: 100%;
  }

  .p-input-icon-left,
  .p-input-icon-right {
    width: 100%;
  }

  .input-text__icon {
    margin-top: -(spacing("2_5"));
  }

  .password-type__icon {
    cursor: pointer;
  }
}
