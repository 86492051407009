@use "../../01-abstracts" as *;

p-radiobutton {
  .p-radiobutton {
    width: spacing(4);
    height: spacing(4);

    &-box {
      width: spacing(4);
      height: spacing(4);
      border-color: $sys-color-inverse_surface;
    }

    &-checked {
      border-color: $sys-color-primary;

      .p-radiobutton-icon {
        color: $sys-color-primary;
        background-color: $sys-color-primary;
        border: spacing("0_5") solid $sys-color-surface;
      }

      .p-radiobutton-box {
        border-color: $sys-color-primary;
      }
    }
  }
}
