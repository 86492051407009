p-chips {
  display: block;
  width: inherit;

  .p-chips {
    width: inherit;
  }

  .p-inputtext {
    width: inherit;
    padding: spacing(1) spacing(2);
    border: none;
  }

  .p-chips-token {
    color: var(--color-primary-invert);
    background-color: var(--color-primary-brand);
  }

  input {
    min-width: size(48);
    padding-right: spacing(2);
    padding-left: spacing(2);
  }

  .p-chips-multiple-container {
    flex-wrap: nowrap;
    overflow: auto;
    overflow-x: auto;
  }
}
