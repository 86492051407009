@use "../01-abstracts" as *;
@use "../02-tools" as *;

// 86c0czrh2 Replace when text shadow token is defined
$text-shadow: #{$ref-shadow-key-level3-x} #{$ref-shadow-key-level3-y}
  #{$ref-shadow-key-level3-blur} #{$ref-shadow-key-color};

.nax-legend {
  &__container {
    @include rounded--large;
    @extend %elevation-level3;

    background-color: $sys-color-surface-container-highest;

    @include for-orientation("portrait") {
      display: flex;
      gap: spacing(2);
    }
  }

  &__data-container {
    @extend %scrollbar-hidden;
    @include rounded--large;

    display: flex;
    flex-grow: 1;
    gap: spacing(1);
    padding: spacing(1);
    overflow-x: auto;

    @include for-orientation("landscape") {
      flex-direction: column-reverse;
    }
  }

  &__tag {
    padding-block: spacing(1);
    padding-inline: spacing("1_5");
    text-shadow: $text-shadow;
    text-wrap: nowrap;
  }

  &__gradient-bar {
    @include rounded--large;

    flex-grow: 1;
    height: spacing(3);
    margin: auto;

    @include for-orientation("landscape") {
      flex-direction: column;
      width: spacing(3);
      height: spacing(52);
    }
  }
}
