@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-tabs {
  &__actions {
    display: flex;

    button {
      @include label--large;
      @include user-action-states(
        $sys-color-on-surface-variant,
        $sys-color-surface
      );

      position: relative;
      display: flex;
      gap: spacing(1);
      align-items: center;
      padding: spacing(3) spacing("3_5");
      overflow: hidden;
      color: $sys-color-on-surface-variant;
      text-wrap: nowrap;
      background-color: $sys-color-surface;
      border-bottom: spacing("0_5") solid $sys-color-outline-variant;

      &.selected {
        @include user-action-states($sys-color-primary, $sys-color-surface);

        color: $sys-color-primary;
        border-color: $sys-color-primary;
      }
    }

    &--vertical {
      button {
        flex-direction: column;
        overflow: hidden;
        text-align: center;
        text-wrap: balance;
        word-break: break-word;

        &.selected {
          border-bottom: none;

          &::after {
            $width: 30%;
            $height: spacing("1_5");

            position: absolute;
            bottom: calc($height / -2);
            left: calc(50% - #{$width}/ 2);
            width: $width;
            height: $height;
            content: "";
            background-color: $sys-color-primary;
            border-radius: spacing(10);
          }
        }
      }
    }
  }
}
