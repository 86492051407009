@use "../01-abstracts" as *;

.relative {
  position: relative;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.hidden {
  display: none !important;
}

.invisible {
  pointer-events: none;
  opacity: 0;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

// Margin
.m-1 {
  margin: spacing(1);
}

.my-1 {
  margin-block: spacing(1);
}

.mt-1 {
  margin-block-start: spacing(1);
}

.my-3 {
  margin-block: spacing(3);
}

.mt-4 {
  margin-block-start: spacing(4);
}

.mb-4 {
  margin-block-end: spacing(4);
}

.my-6 {
  margin-block: spacing(6);
}

.my-auto {
  margin-block: auto;
}

.mx-auto {
  margin-inline: auto;
}

.push-right,
.ml-auto {
  margin-inline-start: auto;
}

.push-left,
.mr-auto {
  margin-inline-end: auto;
}

.push-bottom,
.mt-auto {
  margin-block-start: auto;
}

// Padding
.p-1 {
  padding: spacing(1);
}

.py-1 {
  padding-block: spacing(1);
}

.py-2 {
  padding-block: spacing(2);
}

.px-2 {
  padding-inline: spacing(2);
}

.p-3 {
  padding: spacing(3);
}

.px-3 {
  padding-inline: spacing(3);
}

.pl-3 {
  padding-inline-start: spacing(3);
}

.p-4 {
  padding: spacing(4);
}

.py-4 {
  padding-block: spacing(4);
}

.px-4 {
  padding-inline: spacing(4);
}

.pb-4 {
  padding-block-end: spacing(4);
}

.p-5 {
  padding: spacing(5);
}

.px-5 {
  padding-inline: spacing(5);
}
