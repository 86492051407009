@use "../01-abstracts" as *;

.nax-remember-password {
  background-image: url("./../../../public/images/remember-password_768_1024_72.webp");

  @include for-breakpoint("s") {
    background-image: url("./../../../public/images/remember-password_1920_1080_72.webp");
  }

  @include for-breakpoint("xl") {
    background-image: url("./../../../public/images/remember-password_2560_1440_72.webp");
  }

  @include for-breakpoint("xxl") {
    background-image: url("./../../../public/images/remember-password_3840_2160_72.webp");
  }
}
