@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-home-nav {
  ul {
    @extend %max-w-screen-l;

    display: flex;
    flex-wrap: wrap;
    gap: spacing(4) spacing(6);
    justify-content: center;
    padding-inline: spacing(6);

    @include for-breakpoint("m") {
      gap: spacing(10);
    }
  }

  &__cta {
    width: spacing(18);
  }

  &__icon-container {
    @include user-action-states($sys-color-on_surface, $sys-color-surface);

    width: spacing(14);
    height: spacing(14);
  }
}
