@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-avatar {
  @include rounded--full;

  --_width: 0;
  --_height: 0;

  display: block;
  width: var(--_width);
  height: var(--_height);
  overflow: hidden;
  color: $sys-color-on-surface;
  background-color: $sys-color-surface-container-highest;
  border: spacing(px) solid $sys-color-outline_variant;

  img {
    object-fit: cover;
  }

  // Sizes
  &--xl {
    @include label--large;

    --_width: #{spacing(10)};
    --_height: #{spacing(10)};
  }

  &--lg {
    @include label--large;

    --_width: #{spacing(8)};
    --_height: #{spacing(8)};
  }

  &--md {
    @include label--small;

    --_width: #{spacing(6)};
    --_height: #{spacing(6)};
  }
}
