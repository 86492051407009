@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-marker-preview {
  @include rounded--small;

  position: absolute;
  top: spacing(16);
  left: spacing(4);
  z-index: 2;
  max-width: spacing(96);
  padding: spacing(2) spacing(4);
  background-color: $sys-color-surface;

  &--right {
    right: spacing(16);
    left: inherit;
  }

  &__image-preview img {
    max-height: spacing(40);
  }
}
