@use "../../01-abstracts" as *;

p-inputswitch {
  display: flex;
  align-items: center;

  .p-inputswitch-slider {
    background: $sys-color-surface-dim;

    &::before {
      background: $sys-color-on-surface-variant;
    }
  }

  .p-inputswitch-checked .p-inputswitch-slider {
    background-color: var(--color-primary-brand);

    &::before {
      background-color: var(--color-primary-brand-low-emphasis);
    }
  }

  .p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: none;
  }
}
