@use "../01-abstracts" as *;
@use "../02-tools" as *;

nax-products-selector {
  max-height: 75dvh;
}

.t-recent-products {
  @extend %scrollbar-hidden;
  /* stylelint-disable-next-line unit-allowed-list */
  max-width: 89dvw;
  overflow-x: auto;

  nax-input-chip button {
    width: max-content !important;
  }
}

.nax-map-thumbnail {
  position: absolute;
  top: 0;
  right: 0;
  width: spacing(56);
  height: spacing(64);
  padding: spacing(4);

  .nax-map > .map-container {
    @include rounded--small;

    border: spacing(px) solid $sys-color-outline;
  }
}

.t-drawer-selectors {
  @include for-orientation("portrait") {
    @include even-columns-strict;
  }

  @include for-orientation("landscape") {
    @include grid-auto-fill-48;
  }
}

.t-scroll-to-top {
  &__button {
    margin-right: spacing(4);
  }

  &__container {
    position: sticky;
    bottom: spacing(8);
    display: flex;
    justify-content: end;
  }
}

.nax-root {
  &__offline-indicator {
    position: fixed;
    top: spacing(4);
    right: 48%;
    z-index: 999;
    display: block;
  }
}

.nax-page-lock {
  &__backdrop {
    position: fixed;
    inset: 0;
    z-index: 9998;
    display: grid;
    place-items: center;
    background-color: hsl(from $sys-color-on-surface h s l / 40%);
    backdrop-filter: blur(spacing(1));
  }

  &__content {
    width: spacing(96);
    min-height: spacing(32);
  }
}
