@use "../../01-abstracts" as *;

%prime-input-common {
  color: $sys-color-on-surface;
  background-color: $sys-color-surface;
  border: none;
  outline: spacing("px") solid $sys-color-outline_variant;
  outline-offset: -(spacing("px"));

  &:focus {
    background-color: $sys-color-surface;
    outline-width: spacing("0_5");
    outline-color: $sys-color-primary;
    outline-offset: -(spacing("0_5"));
    box-shadow: none;
  }
}

.p-element,
.p-component {
  font-family: $font-base;
  font-feature-settings: initial;
}

nax-input-date,
nax-input-text,
nax-textarea,
nax-input-number,
nax-input-switch,
nax-select,
nax-multiselect,
nax-input-chips {
  font-family: $font-base;
  font-feature-settings: initial;

  &.ng-invalid.ng-dirty.ng-touched {
    .p-inputtext,
    .p-multiselect,
    .p-dropdown,
    .p-chips-multiple-container {
      border-color: var(--sys-color-error);
    }

    &,
    .p-placeholder,
    ::placeholder,
    .switch-label {
      color: var(--sys-color-error);
    }

    .p-inputswitch-slider {
      border-color: var(--sys-color-error);
    }
  }
}
