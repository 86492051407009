@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-map-hud {
  position: absolute;
  inset: 0;
  z-index: 2;
  display: grid;
  grid-template:
    "filtering ." auto
    ". actions" 1fr
    "bottom bottom" spacing(48) / 1fr auto;
  padding: spacing(5);
  pointer-events: none;
  container-type: inline-size;

  @include for-orientation("portrait") {
    grid-template:
      "filtering" auto
      "actions" 1fr
      "bottom" spacing(48) / 1fr auto;
    padding-block-end: spacing(8);
  }

  &__filtering {
    grid-area: filtering;
    justify-self: center;
    width: min(100%, spacing(128));
  }

  &__actions {
    grid-area: actions;
    place-self: center end;
  }

  &__bottom {
    display: grid;
    grid-area: bottom;
    grid-template-columns: subgrid;
    gap: spacing(3);
    place-self: end center;
    min-width: 0;

    @include for-orientation("portrait") {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      width: 100%;
    }
  }

  &__legend {
    place-self: stretch;
    max-width: 100%;

    @include for-orientation("landscape") {
      place-self: end;
    }
  }

  &__context-actions {
    z-index: 1;
    justify-self: center;
    width: min(100%, spacing(128));
    min-width: 0;
    pointer-events: none;
  }

  &__filtering,
  &__actions,
  &__legend,
  &__context-actions {
    & > * {
      pointer-events: auto;
    }

    @container (width <= #{spacing(60)}) {
      display: none !important;
    }
  }
}
