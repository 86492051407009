@use "../01-abstracts" as *;

.nax-card-end-date {
  .card-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .card-info__box {
    box-sizing: border-box;
    width: 100%;
    padding: spacing(1);
    margin-bottom: spacing(4);

    h2 {
      color: var(--color-green-700);
    }
  }

  .card-info__row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .row-info {
    // width: 50%;
    box-sizing: border-box;
    padding: spacing(1);
  }

  .row-info__second-div {
    background-color: var(--background-color-medium-emphasis);
  }

  .row-info__third-div {
    background-color: var(--background-color-medium-emphasis);
  }
}
