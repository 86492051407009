@use "../../01-abstracts" as *;

p-calendar {
  .p-inputtext {
    height: spacing(10);
    padding-block: spacing("1_5");
    padding-inline: spacing(3) spacing(1);
    color: $sys-color-on-surface;
    background-color: $sys-color-surface;
    border: none;
    border-radius: $sys-shape-corner-small;
    outline: spacing("px") solid $sys-color-outline;
    outline-offset: -(spacing("px"));

    &::placeholder {
      color: $sys-color-outline;
    }

    &:focus {
      background-color: $sys-color-surface;
      outline-width: spacing("0_5");
      outline-offset: -(spacing("0_5"));
      box-shadow: none;
    }
  }

  &.size--small {
    .p-inputtext {
      height: spacing(8);
    }
  }
}

.p-datepicker {
  display: flex;
  flex-direction: column-reverse;

  .p-datepicker-group {
    display: flex;
    flex-direction: column-reverse;

    .p-datepicker-header {
      border-top: spacing(px) solid #e5e7eb;
      border-bottom: none;
    }
  }
}
