@use "../../01-abstracts" as *;
@use "../../02-tools" as *;

nax-table {
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: hidden;

  p-table {
    min-height: 0;
    overflow: hidden;

    .p-datatable-scrollable {
      max-height: 100%;

      @include for-orientation("portrait") {
        min-height: spacing(80);
      }

      .p-datatable-wrapper {
        overflow: auto;
      }
    }
  }

  th,
  td {
    padding: 0 spacing(3);
    color: $sys-color-on-surface;
  }

  th {
    @include title--small;

    min-width: max-content;
    color: $sys-color-on_surface;
    text-transform: capitalize;
    white-space: nowrap;
    background-color: $sys-color-surface_container_high;
  }

  tr {
    // In table rows "height" functions as "min-height". https://stackoverflow.com/questions/19432092/can-i-use-a-min-height-for-table-tr-or-td
    height: spacing(10);
    background-color: $sys-color-surface_container_lowest;

    &:has(.p-highlight) {
      color: $sys-color-on_primary_container;
      background-color: $sys-color-primary_container;
    }

    td {
      @include label--medium;

      max-width: size(350);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  p-tablecheckbox,
  p-tableheadercheckbox {
    .p-checkbox {
      height: auto;
    }

    .p-checkbox-box {
      @include rounded--extra-small;

      width: spacing(4);
      height: spacing(4);
      border-color: $sys-color-inverse_surface;

      &.p-highlight {
        border: none;
      }

      .p-icon {
        width: spacing("2_5");
        height: spacing("2_5");
      }
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .p-datatable-scrollable {
    height: fit-content;
    min-height: 0;
    overflow: hidden;
    border: spacing("px") solid $sys-color-outline_variant;
    border-radius: $sys-shape-corner-medium;
  }

  .p-datatable-thead {
    th:first-child {
      border-top-left-radius: $sys-shape-corner-medium;
    }

    th:last-child {
      border-top-right-radius: $sys-shape-corner-medium;
    }
  }

  .p-paginator {
    @include label--medium;

    gap: spacing(1);
    justify-content: flex-end;
    background-color: $sys-color-surface_container;
    border-radius: 0 0 spacing(3) spacing(3);

    & > * {
      height: unset;
    }

    & > button {
      min-width: spacing(6);
    }

    &::before {
      flex-grow: 1;
      order: -99;
      content: "";
    }

    .p-paginator-current {
      margin: 0;
      overflow: visible;
    }

    .p-paginator-left-content {
      order: -2;
    }

    p-dropdown {
      order: -1;

      .p-dropdown {
        @include rounded--small;

        width: unset;
        min-width: spacing(20);
        height: spacing(8);
        margin: 0;

        .p-inputtext {
          @include body--medium;
        }

        .p-dropdown-trigger-icon {
          width: spacing(3);
          height: spacing(3);
        }
      }
    }
  }

  // .cell--color-error {
  //   color: var(--color-error);
  // }

  // .cell--color-success {
  //   color: var(--color-success);
  // }

  // .cell--color-warning {
  //   color: var(--color-warning);
  // }
}
