@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-dialog {
  &__backdrop {
    position: fixed;
    inset: 0;
    z-index: 9999;
    display: grid;
    place-items: center;
  }

  &__dialog {
    @extend %elevation-level3;
    @include rounded--extra-large;

    min-width: spacing(72);
    max-width: spacing(150);
    color: $sys-color-on-surface;
    background-color: $sys-color-surface-container-high;
  }

  &__footer {
    display: flex;
    gap: spacing(2);
    justify-content: flex-end;
  }
}
