@use "../01-abstracts" as *;

.nax-map {
  .map-container {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  &__loading {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
  }

  .leaflet-control-attribution {
    display: none;
  }

  .leaflet-pane {
    z-index: 1;
  }

  .leaflet-div-icon.marker {
    background-color: transparent;
    border: none;
  }

  .marker__label-id {
    width: max-content;

    // ! important added because leaflet add style into element directly
    width: inherit !important;
    height: inherit !important;
    font-size: size(14);
    color: var(--color-primary-invert);
    text-align: center;
    background-color: rgba(0 0 0 / 50%);
  }

  .marker-cluster-group__transparent-icon {
    opacity: 0;
  }
}
