@use "../01-abstracts" as *;
@use "../02-tools" as *;

.nax-icon {
  @include rounded--full;

  display: inline-block;

  // Prevent icon from being squished when inside a flex container
  flex-shrink: 0;
  vertical-align: middle;

  &--xs {
    width: spacing(3);
    height: spacing(3);
  }

  &--sm {
    width: spacing(4);
    height: spacing(4);
  }

  &--md {
    width: spacing(5);
    height: spacing(5);
  }

  &--lg {
    width: spacing(6);
    height: spacing(6);
  }

  &--xl {
    width: spacing(8);
    height: spacing(8);
  }

  // Not defined in design system BUT used in design system (file drop)
  &--xxl {
    width: spacing(12);
    height: spacing(12);
  }
}
