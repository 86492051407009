@use "../01-abstracts" as *;

.nax-filter-chips {
  display: flex;
  gap: spacing(2);

  &.show-all {
    flex-shrink: 1;
    flex-wrap: wrap;
  }

  &:not(.show-all) {
    overflow: hidden;
  }
}
