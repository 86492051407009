.p-tieredmenu {
  .selected {
    background-color: var(--color-selected-item);
  }

  nax-cta {
    padding: spacing(2);

    button {
      display: flex;
      justify-content: space-between;
      padding: spacing(1);
    }
  }

  &.open-direction--left {
    .p-submenu-list {
      left: -100%;
    }
  }
}
